.contact-pane {
  p > strong {
    display: inline-block;
    width: 8rem;
  }
}
.detailContainer {
  min-height: 400px;
  position: relative;
}
