h3 {
  font-size: 1.6rem;
}
.lead {
  font-size: 1.2rem;
}
.list-unstyled {
  li {
    padding-left: 1.5rem;
    background: url("./../../../assets/img/tick-mark.svg") no-repeat center left /
      1rem auto;
    font-size: 1rem;
    margin: 0.5rem 0;
  }
}
.services-content {
  padding: 5rem 0 7rem;
  .basic-multi-select
    .select__control
    .select__indicators
    .select__indicator.select__dropdown-indicator {
    max-height: 10rem !important;
  }
}
