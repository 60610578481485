.AccountCreated {
  h3, p {
    width: 20rem;
    max-width:100% ;
    margin: 0 auto 1rem;
    text-align: center;
  }
  h3 {
    font-size: 1.6rem;}
  p {
    font-size: 1rem;
  }
  img {
    max-width: 8rem;
    margin: 0 auto;
  }
}
