aside {
  // width: 20rem !important;
  border-radius: 0.625rem;
  border: 0;
  .list-group {
    background-color: #fff;
    border-radius: 0.625rem;
    padding: 1.2rem 2.2rem;
    .list-group-item {
      background: none !important;
      color: #646464;
      font-weight: 500;
      padding: 1.2rem 0 1rem;
      border-color: #eee;
      &.active {
        color: #ff7f00;
        border-color: #eee;
      }
      &:first-child {
        border-top-left-radius: 0.625rem;
        border-top-right-radius: 0.625rem;
      }
      &:last-child {
        border-bottom-right-radius: 0.625rem;
        border-bottom-left-radius: 0.625rem;
      }
    }
  }
}
.card {
  border-radius: 0.625rem;
  label {
    font-weight: 500;
    font-size: 0.875rem;
  }
  .form-control,
  .form-select {
    border-color: #202020;
  }
}

.basicLogo {
  min-height: 12.5rem;

  .img-fluid {
    width: 12rem;
  }
  .image-item {
    position: relative;
    width: 12rem;
    .company-logo {
      height: 12rem;
      width: 12rem;
      background-color: #fff;
      position: relative;
      border-radius: 0.625rem;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
      img {
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        max-width: calc(100% + 1.5rem);
        border-radius: 0.625rem;
        max-height: calc(100% + 1.5rem);
      }
    }
    .btn-close {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }
  }
}

.editCompanyLogo {
  .companyLogo {
    background-color: rgba(255, 255, 255, 0.5);
    border: 2px solid #e2e2e2;
    border-radius: 0.625rem;
    width: 11rem;
    height: 11rem;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  label.btn {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }
}
