.cursor-pointer {
  cursor: pointer;
}
.password-container {
  position: relative;
  .eye-icon {
    border-radius: 0.625rem;
    background-color: #fff;
    padding: 0.7rem 0.75rem 0.5rem;
    position: absolute;
    right: 1px;
    top: 1px;
    cursor: pointer;
    + .form-control {
      padding-right: 3rem !important;
    }
  }
}
