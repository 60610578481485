.community-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .nav-link {
    color: #ff7f00;
    font-weight: normal;
    font-size: 1rem;
  }
}
