.OtherPage {
  position: relative;
  min-height: 400px;
  h2,
  h3 {
    color: rgb(255, 127, 0);
    font-size: 28px;
    font-weight: 500;
    margin: 2rem 0 1rem;
  }
  p {
  }
  ul {
    li {
    }
  }
  h4 {
    color: rgb(255, 127, 0);
    font-size: 24px;
    font-weight: 500;
    margin: 2rem 0 1rem;
  }
}
