$image-dimensions: 12rem;
.companyLogo {
  h3 {
    font-size: 1.6rem;
  }
  .lead {
    font-size: 1.2rem;
  }
  .img-fluid {
    width: $image-dimensions;
    margin: 0 auto;
  }
  .image-item {
    position: relative;
    width: $image-dimensions;
    height: $image-dimensions;
    margin: 0 auto;
    .company-logo {
      height: $image-dimensions;
      width: $image-dimensions;
      background-color: #fff;
      position: relative;
      border-radius: 0.625rem;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
      img {
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        max-width: calc(100% + 3rem);
        border-radius: 0.625rem;
        max-height: calc(100% + 3rem);
      }
    }
    .btn-close {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      background-color: #fff;
      padding: 0.5rem;
    }
  }
}
