.dropdown {
  order: 1;
  @media (max-width: 992px) {
    order: 0;
  }
  .dropdown-menu {
    border: 1px solid #707070;
    width: 16.25rem;
    border-radius: 0.425rem;
    padding: 0;
    li {
      position: relative;
      padding: 1rem;
      h5 {
        font-size: 1rem;
      }
      p {
        font-size: 0.75rem;
      }
      a {
        font-weight: 500;
        background: none !important;
      }
      + li {
        border-top: 1px solid #70707024;
      }
      .dropdown-item {
        color: #202020;
        padding: 0;
      }
    }
  }
}

.jumbotron,
.company-banner {
  position: relative;
  .banner-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
.companyLogoThumbnail {
  border: 2px solid #e2e2e2;
  border-radius: 0.425rem;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.dropdown-menu {
  .companyLogoThumbnail {
    width: 3rem;
    height: 3rem;
  }
}

.verification-alert {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1031;
  background-color: rgba($color: #ff8c00, $alpha: 0.8) !important;
  box-shadow: 0px 0.1875rem 0.375rem rgba($color: #000, $alpha: 0.2);
  border-radius: 0;
  border: 0;
  font-size: 12px;
  padding: 6px 15px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(0.625rem); /* Adjust the blur amount as needed */
    opacity: 0.8; /* Adjust the opacity as needed */
    z-index: -1;
  }
  + .navbar {
    top: 30px;
    + .container {
      padding-top: calc(3rem + 30px) !important;
    }
    + .jumbotron {
      padding-top: calc(4.625rem + 30px) !important;
    }
  }
}
.verification-button {
  font-size: 0.75rem;
  font-weight: normal !important;
  text-decoration: underline !important;
  .spinner-border {
    height: 13px;
    width: 13px;
  }
}
