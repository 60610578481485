// Custom Fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
$sectionSpacing: 3.25rem;
// Example of resetting some Sass variables to their default values
$primary: #ff7f00;
$secondary: #111111;
$success: #1eb258;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f5f5f5;
$dark: #343a40;
$gray: #666666;
$white: #ffffff;
$black: #000000;
$blue: #007bff;

// Resetting typography variables
$font-family-sans-serif: "Poppins", sans-serif, Arial;

// Custom Variables
$navbar-link-font-size: 1rem;
$navbar-link-font-weight: bold;
$navbar-link-text-transform: uppercase;
$navbar-dark-color: #fff;
$navbar-dark-hover-color: $primary;
$navbar-dark-active-color: $primary;

// Button Variables
$btn-border-radius: 0.625rem;
$btn-font-weight: 500;
$btn-padding-x: 1.6875rem;
$btn-padding-y: 0.75rem;
$btn-font-family: "Poppins", sans-serif;

// Input Variables
$input-font-size: 1rem;
$input-padding-y: $btn-padding-y;
$input-padding-x: $btn-padding-x;
$input-placeholder-color: #a8a8a8;
$input-border-radius: $btn-border-radius;
$input-disabled-color: $gray;
$input-disabled-bg: rgba(
  $color: $gray,
  $alpha: 0.17,
);
$input-disabled-border-color: rgba(
  $color: $gray,
  $alpha: 0.17,
);
$form-check-input-border: 1px solid rgba($black, 0.4);

// Heading Variables
$headings-font-family: "Poppins", sans-serif;
$headings-font-weight: bold;
$lead-font-size: 1.25rem;
$lead-font-weight: normal;
$text-muted: #666666;

// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Custom Styles
html {
  font-size: 16px;
  @media (max-width: 1920px) {
    font-size: 15px;
  }
  @media (max-width: 1399.98px) {
    font-size: 14px;
  }
  @media (max-width: 1199.98px) {
    font-size: 13px;
  }
  @media (max-width: 991.98px) {
    font-size: 12px;
  }
  @media (max-width: 767.98px) {
  }
  @media (max-width: 575.98px) {
  }
}
body {
  background-color: $light;
  color: #202020;
  font-weight: normal;
}
.text-blue {
  color: $blue !important;
}
a {
  text-decoration: none;
}
h1,
h1 {
  font-size: 3rem;
}
.py-6 {
  padding-top: $sectionSpacing !important;
  padding-bottom: $sectionSpacing !important;
}
.btn-sm {
  font-size: 0.875rem;
  border-radius: 0.625rem;
  padding: 0.5rem 1.1875rem;
}
.btn {
  &.btn-primary {
    background-color: rgba($color: $primary, $alpha: 1);
    border-color: $primary;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      color: $primary;
      background-color: rgba($color: $primary, $alpha: 0);
      border-color: $primary;
    }
  }

  &.btn-outline-primary {
    color: $primary;
    background-color: rgba($color: $primary, $alpha: 0);
    border-color: $primary;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: rgba($color: $primary, $alpha: 1);
      border-color: $primary;
    }
  }
  &.btn-link {
    color: $primary;
    background-color: rgba($color: $primary, $alpha: 0);
    border-color: rgba($color: $primary, $alpha: 0);
    text-decoration: none;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: rgba($color: $primary, $alpha: 0);
      border-color: rgba($color: $primary, $alpha: 0);
    }
  }
}
.card.featured-card {
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  border: 0;
  padding: 1rem;
  transition: ease-in-out 0.15s;
  &:hover {
    box-shadow: 0 0.5rem 0.375rem rgba(0, 0, 0, 0.3);
  }
  .card-body {
    padding: 1rem 0;
    .card-title {
      font-size: 1rem;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
    }
    .card-text {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .card-footer {
    padding: 0;
    background: none;
    border: none;
    .btn {
      position: relative;
      z-index: 2;
    }
  }
}
.featuredAdd {
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  transition: ease-in-out 0.15s;
  display: block;
  :hover {
    box-shadow: 0 0.5rem 0.375rem rgba(0, 0, 0, 0.3);
  }
  img {
    width: 100%;
    border-radius: 0.625rem;
  }
}
.navbar-brand {
  flex-grow: 0;
  img {
    width: auto;
    height: 2.5rem;
    @media (max-width: 575.98px) {
      height: 2rem;
    }
  }
}
.navbar-nav {
  row-gap: 0;
  column-gap: 1.25rem;
  .nav-link {
    padding: 1.125rem 0 0.5rem !important;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    &::before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      background-color: $primary;
      border-radius: 100%;
      top: 0;
      left: 0;
      transition: ease-in-out 0.15s;
    }
    &.active {
      &::before {
        height: 0.6875rem;
        width: 0.6875rem;
      }
    }
  }
}

header {
  position: relative;
  min-height: 5rem;
}
.jumbotron {
  // background: url("./assets/img/banner.jpg");
  background-size: cover;
  position: relative;
  padding-top: 4.625rem;
  color: #fff;
  &::before {
    content: "";
    position: absolute;
    background-color: rgba($color: #000000, $alpha: 0.6);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  p {
    font-size: 1.375rem;
  }
  .container {
    position: relative;
    z-index: 2;
    padding-top: 9.625rem;
    padding-bottom: 9.625rem;
    .input-group {
      max-width: 39.25rem;
      margin: 0 auto;
      .form-control {
        border-radius: 0.625rem 0 0 0.625rem;
        font-size: 1.125rem;
      }
    }
  }
}
nav {
  &.navbar {
    background-color: rgba($color: #000000, $alpha: 0.8) !important;
    box-shadow: 0px 0.1875rem 0.375rem rgba($color: #000000, $alpha: 0.2);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(0.625rem); /* Adjust the blur amount as needed */
      opacity: 0.8; /* Adjust the opacity as needed */
      z-index: -1;
    }
    .container {
      position: relative;
      z-index: 1;
      color: #fff;
    }
    .navbar-collapse {
      justify-content: space-between !important;
    }
    .btn {
      @media (max-width: 575.98px) {
        padding-left: 1.3rem !important;
        padding-right: 1.3rem !important;
      }
    }
  }
}
section.featured {
  padding: $sectionSpacing 0;
  background-color: $white;
}

h2.sectionHeading {
  font-size: 2.5rem;
  margin: 0 0 2.625rem;
  small {
    display: block;
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: normal;
  }
}
.company-logo {
  border: 1px solid #e2e2e2;
  border-radius: 0.625rem;
  width: 5rem;
  height: 4.5rem;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &.reference-logo {
    height: 3rem;
    width: 3rem;
  }
}
main {
}
.card.company-card {
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  border: 0;
  padding: 1rem;
  transition: ease-in-out 0.15s;
  &:hover {
    box-shadow: 0 0.5rem 0.375rem rgba(0, 0, 0, 0.3);
  }
  .card-body {
    padding: 0.5rem 0 1rem;
    .card-title {
      font-size: 1rem;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
    }
    .card-text {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .card-footer {
    padding: 0;
    position: relative;
    background: none;
    border: none;
    font-size: 1.125rem;
    small {
      font-size: 0.875rem;
      font-weight: 500;
    }
    &::before {
      content: "";
      height: 1px;
      background-color: #e6e6e6;
      margin: 0 auto;
      width: 12.5rem;
      max-width: 80%;
      margin-bottom: 1.2rem;
      display: block;
    }
  }
}
.card.article-card {
  .article-img {
    border: 1px solid #e2e2e2;
    border-radius: 0.625rem;
    width: calc(100% + 2px);
    margin: 0 -1px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    img {
      max-width: calc(100%);
      max-height: calc(100%);
      min-width: calc(100%);
      min-height: calc(100%);
    }
  }
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  border: 0;
  padding: 0;
  transition: ease-in-out 0.15s;
  &:hover {
    box-shadow: 0 0.5rem 0.375rem rgba(0, 0, 0, 0.3);
  }
  .card-body {
    padding: 1rem;
    .card-title {
      font-size: 1rem;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
    }
    .card-text {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .card-footer {
    padding: 0 1rem 1rem;
    background: none;
    border: none;
    .btn {
      position: relative;
      z-index: 2;
    }
  }
}
footer {
  background-color: #202020;
  color: #fff;
  font-size: 1rem;
  padding: 4rem 0 3.8rem;
  a,
  .nav-link {
    color: #fff;
  }
}

.onboarding {
  background-color: #fff;
  .logo-section {
    background: url("./assets/img/building.jpg");
    background-size: cover;
    position: relative;
    color: #fff;
    text-align: center;
    min-height: 100vh;
    @media (max-width: 767px) {
      min-height: 10rem;
    }
    &::before {
      content: "";
      position: absolute;
      background-color: rgba($color: #000000, $alpha: 0.6);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    img {
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18.75rem;
      max-width: 50%;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: stretch;
    justify-content: center;
    @media (max-width: 767px) {
      min-height: calc(100vh - 10rem);
      justify-content: start;
    }
  }
  .continue-using {
    position: relative;
    &::before {
      content: "";
      background-color: $primary;
      height: 3px;
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      right: 0;
    }
    span {
      background-color: $light;
      position: relative;
      z-index: 2;
    }
  }
}
.form-widget {
  background-color: #ffffff;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  .logo {
    padding: $sectionSpacing 0;
    img {
      width: 13.75rem;
    }
  }
  .content {
    width: 33.5rem;
    max-width: 100%;
    margin: 0 auto;
  }
}
.nav-tabs {
  display: flex;
  border: 0;
  border-bottom: 1px solid #aaa !important;
  li {
    flex-grow: 1;
    .nav-link {
      display: block;
      width: 100% !important;
      border: 0;
      border-bottom: 0 solid #ff7f00 !important;
      transition: ease-in-out 0.15s;
      color: #aaa;
      font-weight: normal;
      box-shadow: none !important;
      position: relative;
      &::before {
        background-color: #ff800000;
        content: "";
        height: 3px;
        left: 0;
        right: 0;
        bottom: -1px;
      }
      &:hover {
        color: #ff7f00;
      }
      &.active {
        color: #ff7f00;
        border-bottom-width: 1px !important;
        margin-bottom: -1px;
        background: none;
        &::before {
          background-color: #ff8000;
        }
      }
    }
  }
}
.company-banner {
  // background: url("./assets/img/banner.jpg");
  transition: ease-in-out 0.2s;
  background-size: cover;
  position: fixed !important;
  left: 0;
  top: 0;
  right: 0;
  padding-top: 11rem;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
  color: #fff;
  z-index: 9;
  &::before {
    content: "";
    position: absolute;
    background-color: rgba($color: #000000, $alpha: 0.6);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .logo {
    background-color: rgba(255, 255, 255, 0.5);
    // border: 2px solid #e2e2e2;
    border-radius: 0.625rem;
    width: 9rem;
    min-width: 9rem;
    height: 9rem;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    // top: 4.5rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    + div {
      width: calc(100% - 9rem);
      min-width: calc(100% - 9rem);
      .claimable .name span {
        max-width: 100%;
      }
      .verified .name span {
        max-width: calc(100% - 3.75rem);
      }
    }
  }
  .container {
    display: flex;
    .company-info {
      margin: 0.8rem 1rem;
      .name {
        font-size: 2rem;
        margin-bottom: 0;
        span {
          display: inline-block;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .verifiedBadge {
          display: inline-block;
          vertical-align: baseline;
          height: 1.125rem;
          margin-left: 0.5rem;
        }
      }
      .location {
        font-size: 1rem;
      }
    }
  }
  + .container {
    padding-top: 375px;
  }
  &.scrolled {
    padding-top: 6rem;
    padding-bottom: 1rem;
    z-index: 9;
    .logo {
      width: 5rem;
      min-width: 5rem;
      height: 5rem;
      padding: 0.3rem !important;
      img {
        border-radius: 0.3rem;
      }
      + div {
        width: calc(100% - 5rem);
        min-width: calc(100% - 5rem);
        .claimable .name span {
          max-width: calc(100% - 9rem);
        }
      }
    }
    .container {
      .company-info {
        .name {
          font-size: 1.4rem;
        }
        .btn {
          position: absolute;
          right: 0.6875rem;
          top: calc(50% - 18px);
          margin: 0 !important;
        }
      }
    }
    + .container {
      padding-top: 280px;
    }
  }
  * {
    transition: ease-in-out 0.2s;
  }
}
.company-details {
  .tab-content {
    background-color: #fff;
    border-radius: 0.625rem;
    padding: 1.5rem 2rem;
    font-size: 1rem;
    .list-group {
      list-style: none;
      margin-bottom: 1rem;
      li {
        &:first-child {
          width: 12.5rem;
          max-width: 50%;
          font-weight: 600;
          + li {
            min-width: calc(100% - 12.5rem);
            a {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
.service-pane {
  margin: 0 -0.5rem;
}
.service-pill,
.technology-pill {
  background-color: #f1f1f1;
  border-radius: 0.625rem;
  font-size: 1rem;
  font-weight: 500;
  height: 2rem;
  line-height: 2rem;
  padding: 0 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: calc(100% - 1rem);
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.contact-pane {
  .content {
    width: 33.5rem;
    max-width: 80%;
    margin: 0 auto;
  }
}
.dummy {
  display: block;
}
.basic-multi-select {
  .select__menu {
    .select__menu-list {
      .select__option {
      }
    }
  }
  .select__control {
    border-radius: 0.625rem;
    border-color: rgb(222, 226, 230);
    color: rgb(33, 37, 41);
    transition: ease-in-out 0.15s;
    &.select__control--is-focused {
      border-color: #ffa64c;
      box-shadow: 0 0 0 0.25rem rgba(255, 127, 0, 0.25);
    }
    .select__value-container {
      padding: 0.6rem 1.6rem;
      .select__placeholder {
        font-weight: 400;
        color: #a8a8a8;
      }
      .select__input-container {
      }
      .select__multi-value {
        .select__multi-value__remove {
          transition: ease-in-out 0.15s;
          svg {
            path {
              transition: ease-in-out 0.15s;
              fill: #222;
            }
          }
          &:hover {
            background-color: #ffdebd !important;
            svg {
              path {
                fill: #ff7f00;
              }
            }
          }
        }
      }
    }
    .select__indicators {
      .select__indicator-separator {
        display: none;
      }
      .select__indicator {
        padding: 0.5rem 1rem;
        &.select__clear-indicator {
        }
        &.select__dropdown-indicator {
          display: none;
        }
      }
    }
  }
}
.dropdown-toggle::after {
  vertical-align: 0.6rem;
}
* {
  scrollbar-color: #888 #e7e7e7;
  scrollbar-width: thin;
  scrollbar-height: thin;
}
::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}
::-webkit-scrollbar-track {
  background: #e7e7e7;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.react-datepicker-wrapper {
  width: 100%;
}
.page-loader {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  [role="button"] {
    cursor: default;
  }
  .theContent {
    font-weight: 500;
    font-size: 18px;
    padding-top: 1rem;
  }
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid $primary;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
