.filters {
  background-color: #fff;
  h5 {
    font-size: 1.25rem;
    margin: 0 0 0;
  }
  .list-group {
    .list-group-item {
      .form-label {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }
  .badge {
    cursor: pointer;
    border-radius: 0.625rem;
    font-size: 1rem;
    font-weight: 500;
    height: 2rem;
    line-height: 2rem;
    padding: 0 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: calc(100% - 6rem);
    margin: 1px 2px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ff7f00;
    color: #ff7f00;
    font: Poppins;
  }
  .active {
    color: #ffffff;
    background: #ff7f00;
  }

  .filterButton {
    position: sticky;
    bottom: 0;
    background-color: #fff;
  }
}
